<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, onMounted, watch, nextTick  } from 'vue';
import { useRoute } from 'vue-router'
import { getIndicator } from '@/services/public.service';
import { useFetchAndLoad } from '@/hooks';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    const store = useStore();
    const { callEndpoint } = useFetchAndLoad();
    const fetchAndUpdateIndicator = async () => {
      try {
        const { data } = await callEndpoint(getIndicator());
        store.commit('general/setIndicator', { data });
      } catch (error) {
        console.error(error);
      }
    };

    const route = useRoute()

    const changesForChallenge = async() => {
      if (localStorage.getItem('typeChallenge') === '2') {
        await nextTick()
        document.title = "Karaoke Jam"

        const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement | null
        if (favicon) {
          favicon.href = "https://pentatlon.blob.core.windows.net/images/statics/KaraokeJAM/Icono_Ventana_JAM.png"
        }

        document.querySelectorAll(".ion-page").forEach((elemento) => {
          (elemento as HTMLElement).style.backgroundImage = "url(https://pentatlon.blob.core.windows.net/images/statics/KaraokeJAM/PatronFondoJAM-02.png)"
        })
      }
    }

    onMounted(async () => {
      await fetchAndUpdateIndicator()
      changesForChallenge()
    })

    watch(route, changesForChallenge)

  }
});
</script>
